$start: 1;
$end: 20;

@mixin fontSizes {
  @for $i from $start through $end {
    .j-font-size-#{$i}rem {
      font-size: #{$i/10}rem !important;
    }
  }
}

@include fontSizes;
