.Jarvis-Font-yekan {
  font-family: "Yekan", -apple-system, BlinkMacSystemFont, "Segoe UI",
    sans-serif !important;
}
.Jarvis-Font-iransans {
  font-family: "IRANSans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    sans-serif !important;
}
.Jarvis-Font-default {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
}
.Jarvis-Font-roboto {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
}
.Jarvis-Font-Size-5rem {
  font-size: 0.5rem !important;
}
.Jarvis-Font-Size-6rem {
  font-size: 0.6rem !important;
}
.Jarvis-Font-Size-7rem {
  font-size: 0.7rem !important;
}
.Jarvis-Font-Size-8rem {
  font-size: 0.8rem !important;
}
.Jarvis-Font-Size-9rem {
  font-size: 0.9rem !important;
}
.Jarvis-Font-Size-10rem {
  font-size: 1rem !important;
}
.Jarvis-Font-Size-10rem {
  font-size: 1rem !important;
}

.Jarvis-Font-Size-10px {
  font-size: 10px !important;
}
.Jarvis-Font-Size-11px {
  font-size: 11px !important;
}
.Jarvis-Font-Size-12px {
  font-size: 12px !important;
}
.Jarvis-Font-Size-13px {
  font-size: 13px !important;
}
.Jarvis-Font-Size-14px {
  font-size: 14px !important;
}

.jarvisIconHover {
  opacity: 0.5;
  cursor: pointer;
}
.jarvisIconHover:hover {
  opacity: 1;
}

.cursor-pointer {
  cursor: pointer !important;
}
.cursor-context {
  cursor: context-menu !important;
}
