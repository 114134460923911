.cursor-pointer {
  cursor: pointer;
}
.cursor-context {
  cursor: context-menu;
}
.cursor-drag {
  cursor: move;
}
.dragging {
  font-weight: bold;
}
.main-panel {
  opacity: 0.3;
}
.main-panel:hover {
  opacity: 1;
}
.operational-panel {
  opacity: 0.3;
}
.operational-panel:hover {
  opacity: 1;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 1px 2px #888888;
}
.treegrid-expander {
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.treegrid-indent {
  display: inline-block;
  position: relative;
}
.sort-icon {
  margin-top: -8px;
}
.topPanel {
  opacity: 0.5;
}
.topPanel:hover {
  opacity: 1;
  cursor: pointer;
}
.panel-item:hover {
  color: #11cb5f;
}
.row-selected {
  background-color: #9abbea !important;
}

.progress-bar {
  background-color: transparent;
  position: absolute;
  right: 0;
  // top: 0;
  padding: 10% 50%;
}

//-------Dragging----------
.dragging {
  font-weight: bold;
  background-color: #9abbea;
}
.box {
  height: 30px;
  border: 1px solid;
}
